.App {
  text-align: center;
}
*{
  font-family: 'Inter', sans-serif!important;
}
body{
  box-sizing: border-box!important;
  font-family: 'Inter', sans-serif!important;

}
.custom-datagrid .MuiDataGrid-colCell,
.custom-datagrid .MuiDataGrid-footerContainer {
  display: none;
}
.trial-datagrid .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
  display: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.input-nis input {
  background: #f2f2f2;
  border-radius: 5px;
} 
.input-nis textarea {
  background: #f2f2f2;
  border-radius: 5px;
}
.cell {
  max-width: 300px; /* Set the maximum width */
  word-wrap: break-word; /* Allow words to break and wrap to the next line */
}
.custom-column-style {
  color: #0000008C;
  font-weight: 500;
  font-size: 14px;
}
.hide-header-cell .MuiDataGrid-columnHeaders{
  display:none;
}
.select-nis .MuiSelect-select {
  background: #f2f2f2;
  border-radius: 5px;
}
.select-nis .MuiOutlinedInput-notchedOutline,.input-nis .MuiOutlinedInput-notchedOutline, .autocomplete-nis .MuiOutlinedInput-notchedOutline{
  border: none!important;
}
.autocomplete-nis .MuiOutlinedInput-root {
  background: #f2f2f2;
  padding: 1px;
  
}
[contentEditable="false"] {
  pointer-events: none;
  cursor: not-allowed;
  user-select: none;
}
.custom-quill-editor {
  .ql-editor {
    white-space: pre-wrap; /* This allows line breaks to be displayed */
  }
}
.cell-red {
  background-color: white; /* Red */
  color: #E74C3C;
}
.cell-orange {
  background-color: white; /* Orange */
  color: #E67E22;
}
.cell-blue {
  background-color: white; /* Blue */
  color: #3498DB;
}
.cell-green {
  background-color: white; /* Green */
  color: #2ECC71;
}
.title-red {
  background-color: #E74C3C; /* Red */
  color: white;
}

.title-orange {
  background-color: #E67E22; /* Orange */
  color: white;
}

.title-blue {
  background-color: #3498DB; /* Blue */
  color: white;
}

.title-green {
  background-color: #2ECC71; /* Green */
  color: white;
}
.textarea-nis .MuiOutlinedInput-root{
  height: 499px;
    flex: 1 1 0%;
    resize: none;
    width: 100%;
    min-height: 100%;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    padding: 10px;
    overflow: hidden;
    background: #f2f2f2;
}
.selectedItem {
  background: #f2f2f2;
  color: #000;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: 600!important;
  font-size: 16px;
  color: #000;
  
}


.dialog-content {

  padding: 20px;

  max-width: 500px;

  width: 100%;

  max-height: 80vh;

  overflow-y: hidden!important;

}

 
.dialog-content.show-scroll{
  
  overflow: auto!important;

  
}





@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mobile-view{
  display: none;
}
@media only screen and (max-width: 600px) {
  .desktop-view{
    display: none;
  }
  .mobile-view{
    display: block;
  }
  main{
    padding: 0 16px!important;
  }
  
}


.vertical-timeline {
  position: relative;
}

.timeline-item {
  display: flex;
  margin-bottom: 20px;
}
.timeline-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #002060; /* Change the color as needed */
  margin-right: 20px;
  position: relative;
  
}

.timeline-circle::before {
  content: '';
    position: absolute;
    left: 28%;
    bottom: 30%;
    transform: translate(-35%, 37%);
    width: 30px;
    height: 30px;
    border-radius: 74%;
    border: 2px solid #002060; 
}
.timeline-content {
  display: flex;
  width: 100%;
}

.left-content {
  flex: 1;
  text-align: right;
  margin-right: 20px;
}

.right-content {
  flex: 3;
}
.timeline-connector {
  position: absolute;
  top: 50%;
  left: calc(26% + 9px);
  transform: translateY(-50%);
  width: 2px;
  height: 100%;
  background-color: #002060;
  z-index: 1;/* Ensure the connector is behind the circles */
}
.custom-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-tooltip .label {
  font-weight: bold;
  margin-bottom: 5px;
}

.custom-tooltip .data {
  margin-bottom: 5px;
}

.custom-tooltip .indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}